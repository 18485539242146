// ============================================================================
// Blog Stylesheet
// ============================================================================

.post-media {
  img.post-img {
    width: auto;
    margin: 0 auto;
    padding: 15px;
  }
}

.insta-grid {
  /* fluid 5 columns */
  .grid-sizer,
  .grid-item {
    width: 100%;
    @media (min-width: $screen-sm-min) {
      width: 50%;
    }
    @media (min-width: $screen-md-min) {
      width: 33.33%;
    }

    .blog-post {
      padding: 15px;
    }
  }
}
