.navbar-header {
  background-color: lighten($black, 5%);
  border: none;
  ul {
    li {
      a {
        color: $white !important;
        i {
          color: $white;
        }
      }
    }
  }
}

.navbar-brand {
  img {
    height: 50px;
    @media (min-width: 1200px) {
      height: 125px;
    }
  }
}

.navbar-collapse {
  background-color: $black;
  padding-top: 5px;
  padding-bottom: 10px !important;
}

.navbar-nav {
  float: right;
  padding: 30px;
  > li {
    > a {
      font-size: 14px;
      font-weight: 600;
      color: $white
    }
    .dropdown-menu {
      position: static;
      float: none;
      @media (min-width: 1026px) {
        position: absolute;
        float: left;
      }
    }
  }
}