
form[id$='Form'] {
  fieldset {
    &, > fieldset {
      div.field,
      fieldset.field {
        label {
          font-family: $font-family;
          padding: 0;
          font-size: 14px;
        }

        div.middleColumn {
          margin-bottom: 5px;
          input, select, textarea {
            background: #F8FAFD;
            border-radius: 3px;
            height: 40px;
            padding: 0 10px;
            margin-bottom: 15px;
            font-size: 14px;
            line-height: 25px;
            color: #73879C;
            resize: none;
            vertical-align: middle;
            border: 1px solid #E6E9ED;

          }

          input[type="checkbox"] {
            height: 15px;
            margin-left: 50px;
            @media (min-width: 769px) {
              margin-left: 0;
            }
          }

          p {

          }

          span {

          }
          input {

          }
          select {

          }
          textarea {

          }

        }
      }
    }
  }
  .Actions {
    width: 150px;
    input[type="submit"] {
      color: #FFF;
      background: $green-dark;
      padding: 11px 20px 13px;
      font-family: $font-family;
      font-weight: 700;
      font-size: 16px;
      text-shadow: none;
      -webkit-font-smoothing: antialiased;
      border: none;
      text-transform: uppercase;
      transition: all 200ms ease-out;
      border-radius: 3px;
      box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
      &:hover {
        background: $green;
        color: $green-dark;
      }
    }
  }
}