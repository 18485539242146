// ============================================================================
// Typography Stylesheet
// ============================================================================

@font-face {
	font-family: $font-family;
}

body {
	color: #585858;
}

* {
	word-wrap: break-word;
}

hero {
	font-family: $font-family;
	font-weight: 400;
	font-size: 25px;
	@media (min-width: 768px) {
		font-size: 64px;
	}
}

h1 {
	font-family: $font-family;
	font-weight: 600;
	color: #585858;
}

h2 {
	font-family: $font-family;
	color: #585858;
}

h3 {
	font-family: $font-family;
	color: #585858;
}

h4 {
	font-family: $font-family;
	color: #585858;
	a {
		color: #585858 !important;
	}
}

h5 {
	font-family: $font-family;
	color: #585858;
}

h6 {
	font-family: $font-family;
	color: #585858;
}

li {
	font-family: $font-family;
	font-size: 16px;
	a {
		font-size: 14px;
	}
}

ol {

}

ul:not(.nav) {
	li:not(.link, .current) {

	}
}

p {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	color: #585858;
}

span {
	font-family: $font-family;
	font-size: 14px;
	color: #585858 !important;
}

a {
	font-family: $font-family;
	transition: all ease 500ms;
	&:hover, &:focus, &:active {
		text-decoration: none;
	}
}

hr {

}

blockquote {

}

//Custom CMS Styles
//***************************************

.lead {
	font-size: 20px;
	font-weight: 400;
	line-height: 160%;
}