// ============================================================================
// Overrides
// ============================================================================

#buorg {
  background-color: white;
  padding: 5px;
  border-bottom: 3px solid red;
  box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.3);

  #buorgclose {
    top: 5px;
  }
}

#ForgotPassword {
  display: inline-block;
  margin: 20px 10px;
}

#__bs_notify__ {
  top: auto !important;
  right: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  border-radius: 0 !important;
}

//Theme Overrides
//******************************************************************************

.social {
  li {
    a {
      &:hover {
        i {
          color: #bdbdbd;
        }
      }
    }
  }
}

.inner-top-xs-xs {
  padding-top: 40px;
  @media (min-width: 1200px) {
    padding-top: 0px;
  }
}

#scrollUp {
  background-color: $green;
}

.owl-pagination .owl-page span {
  background: $green;
  .active {
    background: $green-dark;
  }
}

.owl-pagination .owl-page.active span {
  background: $green-dark;
}

.owl-next {
  background-color: $green-dark;
}

.owl-prev {
  background-color: $green-dark;
}

footer.bg-bg {
  width: 100%;
  height: 100%;
  background-color: $black;
  color: $white;
  h4, p {
    color: $white;
  }
}

.footer-bottom {
  background-color: lighten($black, 5%);
  a, p {
    color: $white;
  }
}

.height-md,
#owl-main.height-md .item {
  //height: 69vh;
  @media (max-width: 767px) {
    height: 44vh;
  }
}